<template>
  <div>
    <div ref="topRefH">
      <hsd-header :headerStyle="infoClass"></hsd-header>
    </div>
    <!-- 輪播banner -->
    <div id="bannerCont" @click="changeExperienceStatus(true)">
      <div
        v-if="topNavId=='classInfor'"
        class="page_banner_container page_banner_infor open_try_out_tip"
      >
        <picture>
          <source media="(max-width:550px)" srcset="../../assets/image/page_banner_3_550.jpg" />
          <source media="(max-width:760px)" srcset="../../assets/image/page_banner_3_760.jpg" />
          <source media="(max-width:960px)" srcset="../../assets/image/page_banner_3_960.jpg" />
          <img alt src="../../assets/image/page_banner_3_1180.jpg" />
        </picture>
      </div>
      <!-- iteacher banner -->
      <div
        v-if="topNavId=='iTeacher'"
        class="page_banner_container page_banner_iteacher open_try_out_tip"
      >
        <picture>
          <source media="(max-width:550px)" srcset="../../assets/image/page_banner_1_550.jpg" />
          <source media="(max-width:760px)" srcset="../../assets/image/page_banner_1_760.jpg" />
          <source media="(max-width:960px)" srcset="../../assets/image/page_banner_1_960.jpg" />
          <img alt src="../../assets/image/page_banner_1_1180.jpg" />
        </picture>
      </div>
      <!-- iteacher banner end -->
      <!-- itutor banner -->
      <div
        v-if="topNavId=='iTutor'"
        class="page_banner_container page_banner_itutor open_try_out_tip"
      >
        <picture>
          <source media="(max-width:550px)" srcset="../../assets/image/bule_boy_550.jpg" />
          <source media="(max-width:760px)" srcset="../../assets/image/bule_boy_760.jpg" />
          <source media="(max-width:960px)" srcset="../../assets/image/bule_boy_960.jpg" />
          <img alt src="../../assets/image/bule_boy_1180.jpg" />
        </picture>
      </div>
      <!-- itutor banner end -->
      <div class="banner_container" v-if="topNavId=='home'">
        <section class="slider">
          <div class="banner_slider" style="position:relative">
            <ul class="flex_right" v-if="rightEnter">
              <li v-for="(img,index) in imgList" :key="index" v-show="banners==index">
                <picture>
                  <source
                    v-for="i in sizeArr"
                    :key="i"
                    :media="'(max-width:'+i+'px)'"
                    :srcset="require('../../assets/image/banner_'+img+'_'+i+'.jpg')"
                  />
                  <img
                    alt
                    class="open_try_out_tip"
                    :src="require('../../assets/image/banner_1_1920.jpg')"
                  />
                </picture>
              </li>
            </ul>
            <ul class="slides" v-if="!rightEnter">
              <li v-for="(img,index) in imgList" :key="index" v-show="banners==index">
                <picture>
                  <source
                    v-for="i in sizeArr"
                    :key="i"
                    :media="'(max-width:'+i+'px)'"
                    :srcset="require('../../assets/image/banner_'+img+'_'+i+'.jpg')"
                  />
                  <img
                    alt
                    class="open_try_out_tip"
                    :src="require('../../assets/image/banner_'+1+'_1920.jpg')"
                  />
                </picture>
              </li>
            </ul>
            <div class="banner-circle">
              <ul>
                <li
                  v-for="(value,index) in imgList"
                  @click="selectBanner(index)"
                  :key="index"
                  :class="{'selected':banners==index}"
                ></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- 輪播banner end -->
    <!-- 置底滑動條 -->
    <div
      v-if="!isStudentLogin &&topNavId!=='login'"
      class="ad_bar"
      :class="{'ad_bar_fixed':infoClass}"
      :style="{bottom:infoClass?'0vh':'90vh'}"
    >
      <div class="ad_container">
        <div class="ad_text">
          註冊會員<span class="ad_none_text">即可</span>
          <span class="ad_yellow_text">免費</span>享學習體驗1次
        </div>
        <div class="ad_btn reg_open" @click="setRegisterStep(1)">立即註冊</div>
      </div>
    </div>
    <!-- 置底滑動條 end -->
    <router-view />
    <div
      v-if="!isStudentLogin&&topNavId=='login'"
      class="ad_bar"
      :class="{'ad_bar_fixed':infoClass}"
      :style="{bottom:infoClass?'0vh':'90vh'}"
    >
      <div class="ad_container">
        <div class="ad_text">
          註冊會員<span class="ad_none_text">即可</span>
          <span class="ad_yellow_text">免費</span>享學習體驗1次
        </div>
        <div class="ad_btn reg_open" @click="setRegisterStep(1)">立即註冊</div>
      </div>
    </div>
    <!--注册弹窗显示-->
    <register-dialog :registerDialog="registerStep" @changeStep="setRegisterStep"></register-dialog>
    <!--注册弹窗显示end-->
  </div>
</template>
<script>
import hsdHeader from "../layout/headerHsd";
import registerDialog from "../childComponent/registerDialog";
import defaultImg from "@/assets/image/member_headpic_default.jpg";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      registerStep: 0, //注册
      rightEnter: false, //图片朝右边进入
      sizeArr: [650, 800, 1024, 1360, 1680],
      imgList: [1,1],
      banners: 0,
      bannerInterval: 0,
      infoClass: false,
      topnavAddc: false,
      toLeasense: false,
    };
  },
  components: {
    hsdHeader,
    registerDialog,
  },
  computed: {
    ...mapState([
      "isStudentLogin",
      "isTeacherLogin",
      "isManagerLogin",
      "topNavId",
    ]),
  },
  created() {
    this.sGetLocalstorage();
  },
  methods: {
    ...mapMutations(["changeExperienceStatus"]),
    ...mapActions(["sGetLocalstorage"]),
    getScroll() {
      var scroll = document.documentElement.scrollTop;
      var banner_con_height = document.getElementById("bannerCont")
        .clientHeight;
      if (scroll > parseInt(banner_con_height)) {
        this.infoClass = true;
      } else {
        this.infoClass = false;
      }
    },
    goPlay() {
      this.banners++;
      this.rightEnter = false;
      if (this.banners == this.imgList.length) {
        this.banners = 0;
      }
    },
    //用户注册
    setRegisterStep(id) {
      this.registerStep = id;
    },
    //轮播选项
    selectBanner(i) {
      clearInterval(this.bannerInterval);
      if (this.banners < i) {
        this.rightEnter = true;
      }
      this.banners = i;
      const _this = this;
      this.bannerInterval = setInterval(_this.goPlay, 5000);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.getScroll);
    const _this = this;
    this.bannerInterval = setInterval(() => _this.goPlay(), 5000);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
    clearInterval(this.bannerInterval);
  },
};
</script>
<style scoped>
.flex_right,.slide {
  width: 100%;
  overflow: hidden;
}
.flex_right li,.slide li {
  opacity: 0;
  animation: myframe 5s ease-in-out;
}
@keyframes myframe {
  0% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
.banner-circle {
  width: 100%;
  position: absolute;
  bottom: 20px;
}
.banner-circle ul {
  width: 100%;
  display: flex;
  justify-content: center;
}
.banner-circle li {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: #888888;
}
.banner-circle .selected {
  background-color: rgba(225, 225, 225, 0.8);
}
.el-input__inner::-webkit-input-placeholder {
    color: #757575;
}
</style>